import React from "react"
import { graphql, Link } from "gatsby"
import Header from "../../layouts/header"
import Footer from "../../components/custom-components/FooterCream"
import FloatingBtn from "../../components/custom-components/floatingBtn"
import bg from "../../images/news-event/news-event-banner.webp"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "../../css/custom-css/custom-news-details.css"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import Seo from "../../components/seo"
import { useIntl } from "gatsby-plugin-intl"

const NewsTemplate = props => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""

  const { title, newsEventsDate, images } = intl.locale === "cn" ? props.data.newsCn : props.data.news

  // const { title, newsEventsDate, images } = props.data.news 
  const pathToImage = getImage(images)
  const richContent = intl.locale === "cn" ? props.data.newsCn.description : props.data.news.description
  // const richContent = props.data.news.description
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return (
          <>
            {" "}
            <img
              className="alignleft"
              width="400"
              src={node.data.target.fluid.src}
              alt="news-detail-img"
            />
          </>
        )
      },
    },

    renderMark: {},
  }

  return (
    <>
      <Seo title={title} />
      <Header newsEventsPage />
      <FloatingBtn />
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">
                {intl.formatMessage({ id: "header.newsandevents" })}
              </h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to={`${locale}/`}>
                      {intl.formatMessage({ id: "header.home" })}
                    </Link>
                  </li>
                  <li>{intl.formatMessage({ id: "header.newsandevents" })}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="content-area">
          <div className="container">
            <div className="custom-back-btn">
              <Link to={`${locale}/news-page/news-event`}>
                <span className="custom-back">
                  <i className="fa fa-arrow-left"></i>
                  {intl.formatMessage({ id: "newsandevents.backbtn" })}
                </span>
              </Link>
            </div>
            <div className="row">
              <div className="col-lg-9 m-b30">
                <div className="blog-post blog-single">
                  <div className="dlab-post-meta">
                    <ul>
                      <li className="post-date">
                        {" "}
                        <strong>{newsEventsDate}</strong>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="dlab-post-title ">
                    <h4 className="post-title m-t0">{title}</h4>
                  </div>
                  <div className="dlab-post-media dlab-img-effect zoom-slow">
                    <GatsbyImage image={pathToImage} alt="news-primary-img" />
                  </div>
                  <br />
                  <div className="dlab-post-text/ news-detail">
                    {richContent && renderRichText(richContent, options)}
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <aside className="side-bar sticky-top">
                  <div className="widget widget_archive">
                    <h5 className="widget-title style-1">
                      {intl.formatMessage({
                        id: "newsandevents.categorieslist",
                      })}
                    </h5>
                    <ul>
                      <li title="News">
                        <Link to={"../news"}>
                          {intl.formatMessage({ id: "newsandevents.news" })}
                        </Link>
                      </li>
                      <li title="Event">
                        <Link to={"../event"}>
                          {intl.formatMessage({ id: "newsandevents.events" })}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
  query getInfo($slug: String, $reg: String) {
    news: contentfulNewsevents(
      slug: { eq: $slug }
      node_locale: { regex: $reg }
    ) {
      title
      slug
      newsEventsDate(formatString: "DD MMMM YYYY")
      images {
        gatsbyImageData
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
            __typename
          }
        }
      }
      node_locale
    }
    newsCn: contentfulCnNewsevents(
      slug: { eq: $slug }
      node_locale: { eq: "en-US" }
    ) {
      title
      slug
      newsEventsDate(formatString: "DD MMMM YYYY")
      images {
        gatsbyImageData
      }
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
            __typename
          }
        }
      }
      node_locale
    }
  }
`

export default NewsTemplate
